<!-- 合格证台账打印功能-->
<template>
<div style="margin: 5 auto;">
    <div id="printdiv" style="margin:0 auto;margin-top:1px;">
        <div v-for="(item_0,index) in groupdata"  style="page-break-after: always;">
            <div style="margin:0 auto;float:right">
                <Button type="warning"  @click="previewdata('printdiv_'+index)">打印预览</Button>
                <Button type="primary" style="margin-left: 15px;" @click="printdata('printdiv_'+index)">直接打印</Button>
            </div>
            <div :id="'printdiv_'+index" style="width:1300px;height:920px;margin-top: 10px;clear: both;">
                <table  style="width: 100%;height:5%;word-wrap: break-word; word-break: break-all;" >
                    <tr>
                        <td width="25%">

                        </td>
                        <td width="50%" style="text-align: center;">
                            <h2>入库单</h2>
                        </td>
                        <td width="25%">
                        </td>
                    </tr>
                </table>
                <table id="tbl"  border="1px solid black" cellspacing="0"  style="margin:0 auto;width:100%;height:80%"  >
                    <tr style="height: 30px;font-size: 16px;font-weight: bold;font-family: '楷体';padding-left: 4px;">
                        <th align="left">序号</th>
                        <th align="center" valign="center">供应产品名称</th>
                        <th align="center" valign="center">规格型号</th>
                        <th align="center" valign="center">供方名称</th>
                        <th align="center" valign="center">适用车型</th>
                        <th align="center" valign="center">数量</th>
                        <th align="center" valign="center">单位</th>
                        <th align="center" valign="center">入库时间</th>
                    </tr>
                    <tr v-for="(item,index) in item_0.data"  style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
                        <td>{{index +1}}</td>
                        <td>{{item.product}}</td>
                        <td>{{item.productmodelno}}</td>
                        <td>{{item.supplier}}</td>
                        <td>{{cgdetail.modelno}}</td>
                        <td>{{item.product.indexOf("电池") != -1? 0: cgdetail.count}}</td>
                        <td>{{item.unit}}</td>
                        <td>{{getexpectdate(cgdetail.madeyear,cgdetail.mademonth)}}</td>
                    </tr>
                    <tr v-for="index in (26-item_0.data.length)" style="height: 20px;font-size: 12px;font-family: '楷体';padding-left: 4px;">
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                        <td ></td>
                    </tr>
                </table>
                <table  style="width: 100%;height: 5%;margin-top: 8px;word-wrap: break-word; word-break: break-all;" >
                    <tr style="text-align: left;">
                        <td width="33%">
                            检验: {{modelcfg.partchecker}}
                        </td>
                        <td width="33%" >
                            仓库签收: {{modelcfg.partintosigner}}
                        </td>
                        <td width="33%">
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</div>
</template>
 <script>
    import {getPrinters,public_print,public_print_with_dir,public_preview_with_dir} from '@/zsjs/zskjprinter.js'
    import * as sysTool from '@/zsjs/systemTool.js'
    export default {
        name:'RkdPrint',
        props:['suppliers','cgdetail'],
        data(){
            return {
                modelcfg:this._self.$root.$store.state.ModelCfg,
                groupdata:[]
            }
        },
        mounted(){
            console.log(this.suppliers)
            console.log(this.cgdetail)
            this.groupdata = this.convertdata()
            console.log(this.groupdata)
        },
        methods:{
            getpreDate(maodate){
                return sysTool.getNextDate(maodate,-1)
            },
            formatDate(date){
                if(date !=null)
                    return  (new Date(date)).Format('yyyy-MM-dd')
                else
                    return ''    
            },
            getexpectdate(y,m){
                return y+'-'+this.cgdetail.mademonth+'-22'
            },
            getpurchasedate(y,m){
                return y+'-'+this.cgdetail.mademonth+'-15'
            },
            previewdata(divid){
                public_preview_with_dir(divid,1,2);
            },
            printdata(divid){
                public_print_with_dir(divid,1,2);
            },
            convertdata(){
                var map = {},
                 dest = [];
                for(var i = 0; i < this.suppliers.length; i++){
                     var ai = this.suppliers[i];
                     if(!map[ai.supplier]){
                      dest.push({
                       supplier: ai.supplier,
                       data: [ai]
                      });
                      map[ai.supplier] = ai;
                     }
                    else{
                      for(var j = 0; j < dest.length; j++){
                       var dj = dest[j];
                       if(dj.supplier == ai.supplier){
                            dj.data.push(ai);
                            break;
                       }
                      }
                    }
                }
                return dest;
            }
        },
        watch:{
            cgdetail(n){
                console.log(this.cgdetail)
            },
            suppliers (n){
                console.log(this.suppliers)
            },
            modelcfg (n){
                console.log(this.modelcfg)
            }
        }
    }
</script>

<style type="text/css">
.printtd{
    padding-top:5px;
    padding-bottom:5px;
    height:40px;
}

#tbl {
    
}

#tbl  th {
    font-size: 16px;
    font-weight: bold;
}
#tbl  td{
    font-size: 12px;
    font-family: "楷体";
    padding-left: 4px;
}
</style>